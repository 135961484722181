<template>
  <div id="degustation">
    <Menu page="degustation"/>

    <section class="box box1">
      <div class="imgBox">
        <img src="../assets/images/degustation_1.jpg" alt="degustation1" v-if="screenWidth>640"/>
        <img src="../assets/images/degustation_1_m.jpg" alt="degustation1m" v-if="screenWidth<=640"/>
      </div>
    </section>

    <section class="box experience simpleBox">
      <div class="textBox" data-aos="fade-left" data-aos-duration="1000">
        <div :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">
          <span>{{ $t('degustation.box2.title.first') }}</span>
          <span>{{ $t('degustation.box2.title.second') }}</span>
        </div>
        <p :class="$root._i18n._vm.locale ==='ja' ? 'text ja' : 'text'">{{ $t('degustation.box2.text') }}</p>
      </div>
      <div class="imgBox">
        <img src="../assets/images/degustation_2.jpg"
             alt="home_4"
             data-aos="fade-down"
             data-aos-easing="linear"
             data-aos-duration="1000"/>
      </div>
    </section>
    <section class="menu">
      <div class="titleText private">
        <span>{{ $t('degustation.box3.private.text') }}</span>
        <router-link to="/contact">
          <button class="cta private">{{ $t('degustation.box3.private.cta') }}</button>
        </router-link>
      </div>
      <div class="menuBox">
        <p :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">{{ $t('degustation.box3.menu1.title') }}</p>
        <p class="text">{{ $t('degustation.box3.menu1.line1') }}</p>
        <p class="text">{{ $t('degustation.box3.menu1.line2') }}</p>
        <p class="text">{{ $t('degustation.box3.menu1.line3') }}</p>
        <p class="text">{{ $t('degustation.box3.menu1.line4') }}</p>
        <p class="text">{{ $t('degustation.box3.menu1.line5') }}</p>
        <p class="text">{{ $t('degustation.box3.menu1.line6') }}</p>
      </div>
      <hr/>
      <div class="menuBox">
        <p :class="$root._i18n._vm.locale ==='ja' ? 'titleText ja' : 'titleText'">{{ $t('degustation.box3.menu2.title') }}</p>
        <p class="text">{{ $t('degustation.box3.menu2.line1') }}</p>
        <p class="text">{{ $t('degustation.box3.menu2.line2') }}</p>
        <p class="text">{{ $t('degustation.box3.menu2.line3') }}</p>
        <p class="text">{{ $t('degustation.box3.menu2.line4') }}</p>
      </div>
       <p class="infos price">{{ $t('degustation.priceInfos') }}</p>
      <a href="https://bookings.zenchef.com/results?rid=365417&pid=1001" target="_blank">
        <button class="cta">{{ $t('degustation.cta') }}</button>
      </a>
      <p class="infos">{{ $t('menu.overlayText') }}</p>
    </section>
    <Footer/>
  </div>
</template>

<script>
import Footer from '../components/Footer';
import Menu from '../components/Menu';

export default {
  name: 'degustation',
  components: {
    Footer,
    Menu,
  },
  props: {},
  data() {
    return {
      screenWidth: screen.width,
    };
  },
  mounted() {
  }
}

</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,700;1,400;1,700&display=swap');


.box1 {
  margin: 0;
}

.bold {
  font-weight: bold;
}

.infos {
  margin: 25px 0;
}

.infos.price {
  margin: 5px 0 0;
}
.cta {
  margin: 150px 0 10px;
}

.private.cta {
  margin: 50px 0 210px;
}

.experience {
  margin: 210px 0 160px;
}

.experience .textBox {
  padding: 10vw 5vw;
}

.box.box1 {
  height: auto;
}

.imgBox {
  height: auto;
}

hr {
  height: 1px;
  width: 500px;
  background: #660032;
  margin: 150px auto;
}

.box1 .imgBox {
  width: 100%;
}


.box1 .imgBox img, .box3 .imgBox img {
  width: 100%;
  height: auto;
}

.imgBox img {
  width: 85%;
}

.box1.textBox {
  text-align: center;
  padding: 0;
  margin: 0 auto;
}

.box2 .textBox {
  width: 50%;
}

.simpleBoxImgRight .imgBox {
  width: 60%;
}

.simpleBoxImgRight .textBox {
  padding: 6%;
  width: 40%;
}

.titleText::after {
  display: none;
}

.experience .titleText::after {
  display: block;
}

.menu {
  text-align: center;
}

.menuBox .text {
  color: #660032;
  font-family: "EB Garamond", sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin: 45px 0;
}

.infos {
  color: #646470;
  margin-bottom: 200px;
}

@media (max-width: 640px) {
  .titleText.ja::after {
    top: 92px;
  }

  hr {
    width: 200px;
  }

  .menuBox .text {
    font-size: 28px;
  }

  .experience {
    margin: 60px 0 106px;
  }

  .experience .textBox {
    padding: 10vw 0;
  }

  hr {
    margin: 80px auto;
  }

  .cta {
    margin: 38px 0 99px;
  }
}


</style>